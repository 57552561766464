<template>
  <!--筛选-->
  <div class="sidebar_box">
    <van-popup v-model="all.isShow" position="right" :style="{ height: '100%' }">
      <div class="sidebar_son">
        <div class="sidebar_right">
          <div>
            <h1>{{ all.dataList && all.dataList.priceList && all.dataList.priceList.title }}</h1>
            <div class="sidebar_price">
              <input v-model="minPriceVal" type="text" class="sidebar_ipt" placeholder="最小值">
              <div />
              <input v-model="maxPriceVal" type="text" class="sidebar_ipt" placeholder="最大值">
            </div>
            <ul v-if="all && all.dataList && all.dataList.priceList">
              <li
                v-for="(iptItem, index) in all.dataList.priceList.item"
                :key="index"
                :class="index == idx ? 'bg_ipt lis' : 'lis'"
                @click="dxipt(index,iptItem)"
              >
                {{ iptItem.min }}-{{ iptItem.max }}
              </li>
            </ul>
          </div>
          <div>
            <h1>
              <span>{{ all.dataList.brandList.title }}</span>
              <span @click="isHideType =! isHideType">
                <span class="img_go" :class="isHideType ? 'img_rotate' :''" />
              </span>
            </h1>
            <!--品牌-->
            <ul v-if="all && all.dataList && all.dataList.brandList" :class="isHideType ? '':'ul'">
              <li
                v-for="(dataItem,index) in all.dataList.brandList.item"
                :key="index"
                ref="ipt"
                class="list_li"
                @click="btnBrand(index,dataItem)"
              >
                <span>{{ dataItem.brandName }}</span>
              </li>
            </ul>
            <div v-if="all.dataList.brandList.item==0">暂时无数据</div>
          </div>
          <div>
            <h1>
              <span>{{ all.dataList.categoryList.title }}</span>
              <span @click="isHideClass =! isHideClass">
                <span class="img_go" :class="isHideClass? 'img_rotate' :''" />
              </span>
            </h1>
            <!--分类-->
            <ul :class="isHideClass ? '':'ul'">
              <li
                v-for="(item,index) in all.dataList.categoryList.item"
                :key="index"
                :class="isIndexs==index ? 'bg_ipt lis list_li': 'lis list_li' "
                @click="btnCateGory(item);isIndexs=index"
              >
                <span>{{ item.categoryThreeName }}</span>
                <span>{{ item.categoryFirstName }}</span>
              </li>
            </ul>
          </div>
          <div>
            <!--公用商品-->
            <h1>{{ all.dataList.channelList.title }}</h1>
            <ul>
              <li
                v-for="(item,index) in all.dataList.channelList.item"
                :key="index"
                class="lis list_li"
                :class="isIndex==index ? 'bg_ipt': ''"
                @click="channelId(item);isIndex=index"
              >
                <span>{{ item.channelName }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="sidebar_btn">
          <button @click="btnReset">重置</button>
          <button class="sidebar_button" @click="btnDetermine">确定</button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
/* eslint-disable eqeqeq */
import Vue from 'vue'
import { Button, Cell, Lazyload, List, Popup, Search, Sticky, Tab, Tabs, Toast } from 'vant'

Vue.use(Tab).use(Tabs).use(List).use(Cell).use(Search).use(Toast).use(Sticky).use(Popup).use(Button).use(Lazyload)

export default {
  name: 'RightSidebar',
  // props:["dataSxlist","show"],
  props: {
    dataSxlist: {
      type: Object,
      default: () => {
        return {
          dataList: '', // 接口 数据
          btnDetermine: () => {
          } // 点击确定按钮
        }
      }
    }
  },
  data() {
    return {
      isHideType: false, //  折叠
      isHideClass: false, //  折叠
      isIndex: 0,
      isIndexs: 0,
      orderlist: [],
      idx: '',
      sxlist: '',
      minPriceVal: '',
      maxPriceVal: '',
      type: true,
      searchParams: {
        sorttype: 1, //  1 全部  2 销量  3 价格
        sort: 0, // 1价格正序  0 倒序
        channelId: 'all',
        mid: window.localStorage.getItem('uid'),
        keyword: '',
        isCoupon: 0,
        page: 1,
        pageSize: 10,
        categoryFirstId: '',
        categoryThreeId: '',
        minprice: '',
        maxprice: '',
        brandId: ''
      },
      sortType: 1// 默认搜索类型
    }
  },
  computed: {
    all() {
      const Obj = this.dataSxlist
      return Obj
    }
  },
  mounted() {

  },
  methods: {
    dxipt(idx, item) {
      this.minPriceVal = ''
      this.maxPriceVal = ''
      this.idx = idx
      this.searchParams.minprice = item.min
      this.searchParams.maxprice = item.max
    },
    // 品牌点击
    btnBrand(ind, item) {
      // 判断颜色bgipt
      if (this.$refs.ipt[ind].className.indexOf('bg_ipt') == -1) {
        this.$refs.ipt[ind].className = 'list_li bg_ipt'
        this.orderlist.push(item.brandId)
      } else {
        this.$refs.ipt[ind].className = 'list_li'
        this.orderlist.splice(this.orderlist.indexOf(item.brandId), 1)
      }
    },
    // 点击重置按钮
    btnReset() {
      this.idx = ''
      this.sxlist = ''
      this.minPriceVal = ''
      this.maxPriceVal = ''
      this.isIndex = ''
      this.isIndexs = ''
      this.searchParams = {
        indexKey: 'brandId',
        channelId: 'all',
        categoryFirstId: '',
        categoryThreeId: '',
        keyword: '',
        sorttype: 1, //  1 全部  2 销量  3 价格
        sort: 1, // 1价格正序  0 倒序
        page: 1,
        pageSize: 10,
        minprice: '',
        maxprice: '',
        brandId: ''
      }
      this.$refs.ipt.forEach((item) => {
        item.className = 'list_li'
      })
      this.orderlist = []
    },
    // 分类
    btnCateGory(item) {
      this.searchParams.categoryThreeId = item.categoryThreeId
      this.searchParams.categoryFirstId = item.categoryFirstId
    },
    // 供应商
    channelId: function(id) {
      this.searchParams.channelId = id.channelId
    },
    // 筛选 点击 确定 按钮
    btnDetermine() {
      if (this.minPriceVal.length != 0 || this.maxPriceVal.length != 0) {
        this.searchParams.minprice = this.minPriceVal
        this.searchParams.maxprice = this.maxPriceVal
      }
      this.searchParams.keyword = this.$route.query.searchKeyword
      this.searchParams.brandId = this.orderlist.join(',')
      // 公共接口
      this.all.isShow = false
      this.all.btnDetermine(this.searchParams)
    }
  }

}
</script>

<style scoped lang="less">
.sidebar_box {
  .sidebar_son {
    padding-top: 25px;
    width: 300px;
    height: 100%;
    overflow-x: hidden;
    position: relative;
    box-sizing: border-box;

    .sidebar_right {
      height: calc(100% - 40px);
      width: 100%;
      overflow-x: hidden;
      overflow-y: scroll;

      h1 {
        text-align: left;
        font-size: 14px;
        padding: 12px 0 0 12px;
        box-sizing: border-box;
      }
    }

    .sidebar_btn {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #ffffff;

      button {
        width: 45%;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 15px;
      }

      .sidebar_button {
        color: #ffffff;
        background: red;
      }
    }

    h1 {
      margin: 5px 0;
      position: relative;

      .img_go {
        display: inline-block;
        background: url("../../assets/images/MoreIocn.png") no-repeat;
        background-size: auto 100%;
        width: 8px;
        height: 12px;
        position: absolute;
        right: 12px;
        transform: rotate(90deg);
      }

      .img_rotate {
        transform: rotate(-90deg);
      }
    }

    .ul {
      width: 100%;
      height: 94px;
      overflow: hidden;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 5px;

      .lis {
        margin-top: 5px;
        margin-left: 3%;
        height: 25px;
        line-height: 25px;
        background: #f8f8f8;
        border-radius: 15px;
        text-align: center;
        width: 28%;
        padding: 0 10px;
        font-size: 12px;
        overflow: hidden;
      }

      .list_li {
        width: 30%;
        margin-top: 5px;
        height: 25px;
        line-height: 25px;
        background: #f8f8f8;
        border-radius: 15px;
        text-align: center;
        font-size: 12px;
        position: relative;

        span {
          display: inline-block;
          width: fit-content;
          text-align: center;
          line-height: 1;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) scale(0.6);
        }

      }

      .bg_ipt {
        color: rgb(236, 185, 185);
        background: rgb(250, 238, 238);
        border: 1px solid rgb(236, 185, 185);
      }
    }
  }

  .sidebar_price {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .sidebar_ipt {
      width: 100px;
      border-radius: 10px;
      height: 25px;
      background: #f8f8f8;
      text-align: center;
    }

    div {
      width: 20px;
      height: 1px;
      background: #eeeeee;
    }
  }
}
</style>
